
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { rulesMixin } from '@/mixins/rulesMixin'
import MergedValueModule from '@/components/ThirdPartyFeeds/MergedValueModule.vue'
import { TimezoneFields } from '../../../types/third_party_feed_types'
import { isNotNullOrUndefined } from '../../../utils/commonUtils'
import _ from 'lodash'

@Component({
  components: { MergedValueModule },
  mixins: [rulesMixin]
})

export default class ModuleForm extends Vue {
  @Prop({ required: false, default: null }) index: number
  @Prop({ required: false, default: null }) columns: string[]
  @Prop({
    required: false,
    default: () => {
      return { name: '', type_of_rule: '', values: [{ type_of_value: '' }], mandatory: false }
    }
  }) defaultField: any
  @Prop({ required: false, default: false }) openNaming: boolean
  @Prop({ required: false, default: null }) type: string

  timeoutHandle: any = null
  field: any = {}
  query: string = ''

  ruleTypes: any[] = [
    { text: 'Direct Input', value: 'FIXED' },
    { text: 'Raw SQL Input', value: 'RAW' },
    { text: 'Merged Value', value: 'MERGE' }
  ]

  directInputTypes: any[] = [
    { text: 'Input Fixed Value', value: 'FIXED' },
    { text: 'Direct Column', value: 'COLUMN' },
    { text: 'Regexp on Column', value: 'REGEXP' },
    { text: 'Regexp on Filename', value: 'FILENAME' }
  ]

  mounted () {
    this.initializeField(this.defaultField)
  }

  getNames () {
    if (this.type === 'mandatory') {
      if (this.field.mandatory) {
        return this.$store.getters.getMandatoryFieldsNames
      } else {
        return this.$store.getters.getNiceToHaveFieldsNames
      }
    } else if (this.type === 'variables') {
      return this.$store.getters.getVariablesNames
    } else if (this.type === 'metrics') {
      return this.$store.getters.getMetricsNames
    }
    return []
  }

  initializeField (value: any) {
    this.field = { ...value }
    if (isNotNullOrUndefined(value.type_of_rule) && value.type_of_rule === 'RAW' && isNotNullOrUndefined(value.values[0])) {
      this.query = this.removeParentheses(value.values[0])
      this.field.values[0] = {
        type_of_value: 'RAW',
        query: this.query
      }
    }
  }

  removeParentheses (query: string) {
    let res = query
    if (res.charAt(0) === '(') {
      res = res.slice(1)
    }
    if (res.charAt(res.length - 1) === ')') {
      res = res.substring(0, res.length - 1)
    }
    return res
  }

  @Watch('field', { deep: true })
  onFieldChange (value: any) {
    _.debounce(() => {
      this.$emit('update-field', this.index, value)
    }, 1000)()
  }

  @Watch('query', { deep: true })
  sqlInput (value: string) {
    if (this.field.type_of_rule === 'RAW') {
      this.field.values[0].query = value
    }
    if (value !== '') {
      _.debounce(() => {
        this.$emit('sql-input')
      }, 1000)()
    }
  }

  get isTimezone (): boolean {
    if (isNotNullOrUndefined(this.field.name) && this.field.name.includes('timezone')) {
      this.field.type_of_rule = 'FIXED'
      this.field.values = [{ type_of_value: 'FIXED', value: TimezoneFields.GMT }]
      return true
    }
    return false
  }

  getTimezones () {
    return Object.values(TimezoneFields)
  }

  removeField () {
    this.$emit('remove-field', this.index)
  }

  addMergedValue () {
    if (this.field.type_of_rule === 'MERGE') this.field.values.push({ type_of_value: '' })
  }

  updateValue (index: number, updated: any) {
    this.field.values[index] = updated
  }

  @Watch('field.type_of_rule')
  resetRules (value: any) {
    if (value !== '') {
      if (value === 'MERGE' && JSON.stringify(this.field) !== JSON.stringify(this.defaultField)) {
        this.field = { ...this.field, values: [{ type_of_value: '' }] }
      } else if (value !== 'MERGE' && isNotNullOrUndefined(this.field.values)) {
        this.field.values = [this.field.values[0]] // avoid getting multiple values in a non-merge
      }
      if (value === 'RAW') {
        this.field.values[0] = {
          type_of_value: 'RAW',
          query: ''
        }
      } else {
        delete this.field.values[0].query
      }
    }
  }
}
