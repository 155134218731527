
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { rulesMixin } from '@/mixins/rulesMixin'
import ModuleForm from '@/components/ThirdPartyFeeds/ModuleForm.vue'
import _ from 'lodash'
import MetricsMapper from '@/components/ThirdPartyFeeds/MetricsMapper.vue'

@Component({
  components: { MetricsMapper, ModuleForm },
  mixins: [rulesMixin]
})

export default class ThirdPartyTransformations extends Vue {
  @Prop({ required: false, default: false }) value: boolean
  @Prop({ required: false, default: {} }) currentFile: Object
  @Prop({ required: false, default: [''] }) fileLines: any[]
  @Prop({ required: false, default: [''] }) fileHeaders: any[]

  transformedFileLines: any[] = []
  transformedFileHeaders: any[] = []

  mandatoryFields: any[] = []
  variables: any[] = []
  metrics: any[] = []

  successSave: boolean = false
  errorSave: boolean = false
  errorMessage: string = ''
  successSimulation: boolean = false
  errorSimulation: boolean = false
  invalidSql: boolean = false

  metricsMapper: boolean = false

  get dialog (): boolean {
    return this.value
  }

  set dialog (value: boolean) {
    this.$emit('input', value)
  }

  get mandatoryFieldsNames (): any[] {
    return this.$store.getters.getMandatoryFieldsNames
  }

  get variablesNames (): any[] {
    return this.$store.getters.getVariablesNames
  }

  get metricsNames (): any[] {
    return this.$store.getters.getMetricsNames
  }

  canSimulate (): boolean {
    if (this.invalidSql) return false

    let res = true
    for (const field of this.mandatoryFields) {
      if ((field.name === '') || (
        (field.mandatory === true) && (field.type_of_rule === '')
      )) {
        res = false
      }
    }
    return res
  }

  @Watch('value')
  initialModules (value: boolean) {
    if (value) {
      this.mandatoryFields = [...this.$store.getters.getMandatoryFields]
      this.variables = [...this.$store.getters.getVariables]
      this.metrics = [...this.$store.getters.getMetrics]
    }
  }

  get loadingMetricsMapper (): boolean {
    return this.$store.getters.getLoadingRefEvents
  }

  get displayVariables (): boolean {
    return this.$store.getters.getCurrentFeed.granularity === 'ratio'
  }

  addMandatoryFieldModule () {
    this.mandatoryFields.push(
      {
        name: '',
        type_of_rule: '',
        values: [
          {
            type_of_value: '',
            value: ''
          }
        ],
        mandatory: false,
        localId: this.$store.getters.getLocalMandatoryFieldId
      })
    this.$store.commit('setLocalMandatoryFieldId', this.$store.getters.getLocalMandatoryFieldId + 1)
  }

  updateMandatoryField (index: number, updated: any) {
    this.mandatoryFields[index] = updated
  }

  deleteMandatoryField (index: number) {
    this.mandatoryFields.splice(index, 1)
  }

  addVariableModule () {
    this.variables.push(
      {
        name: '',
        type_of_rule: '',
        values: [
          {
            type_of_value: '',
            value: ''
          }
        ],
        mandatory: false,
        localId: this.$store.getters.getLocalVariableId
      })
    this.$store.commit('setLocalVariableId', this.$store.getters.getLocalVariableId + 1)
  }

  updateVariable (index: number, updated: any) {
    this.variables[index] = updated
  }

  deleteVariable (index: number) {
    this.variables.splice(index, 1)
  }

  addMetric () {
    this.metrics.push(
      {
        name: '',
        type_of_rule: '',
        values: [
          {
            type_of_value: '',
            value: ''
          }
        ],
        mandatory: false,
        localId: this.$store.getters.getLocalMetricId
      })
    this.$store.commit('setLocalMetricId', this.$store.getters.getLocalMetricId + 1)
  }

  updateMetric (index: number, updated: any) {
    this.metrics[index] = updated
  }

  deleteMetric (index: number) {
    this.metrics.splice(index, 1)
  }

  encodeSqlInputs (variables: any[]) {
    let workingVars = _.cloneDeep(variables)
    for (let variable of workingVars) {
      if (variable.type_of_rule === 'RAW') {
        variable.values[0].query = btoa(variable.values[0].query)
      }
    }
    return workingVars
  }

  async validateSqlInputs () {
    const data = {
      metrics: this.encodeSqlInputs(this.metrics),
      mandatory_fields: this.encodeSqlInputs(this.mandatoryFields),
      variables: this.encodeSqlInputs(this.variables)
    }
    const res = await this.$apiCaller.postValidateThirdPartySql(data)
    if (res.status === 200 && res.data === 'SQL is valid') {
      this.invalidSql = false
    } else {
      this.invalidSql = true
      this.successSimulation = false
    }
  }

  async callSimulationApi () {
    const data = {
      source_id: 'unused',
      simulation_id: this.$store.getters.getSimulationId,
      granularity: this.$store.getters.getCurrentFeed.granularity,
      variables: this.encodeSqlInputs(this.variables),
      mandatory_fields: this.encodeSqlInputs(this.mandatoryFields),
      metrics: this.encodeSqlInputs(this.metrics)
    }
    const res = await this.$apiCaller.postThirdPartySimulate(data)
    return res
  }

  async simulate () {
    const res = await this.callSimulationApi()
    if (res.status === 200) {
      const file = JSON.parse(res.data)
      this.transformedFileHeaders = this.createHeaderTable(file.header)
      this.transformedFileLines = file.lines
      const metricsIndex = file.header.indexOf('metrics')
      for (let line of this.transformedFileLines) {
        const metrics = JSON.parse((line[metricsIndex].slice()).replace(/'/g, '"'))
        let metricsText = ''
        for (let metric of metrics) {
          metricsText += '<b>' + metric.name + ':</b> ' + metric.value + ', '
        }
        line[metricsIndex] = metricsText
      }
      this.openAlert('successSimulation')
    } else {
      this.transformedFileHeaders = []
      this.transformedFileLines = []
      this.errorMessage = res.response && res.response.data && res.response.data.errors ? res.response.data.errors : res
      this.openAlert('errorSimulation')
    }
  }

  async save (activate: boolean = false) {
    const cleanedMandatoryFields = this.encodeSqlInputs(this.mandatoryFields)
    for (let field of cleanedMandatoryFields) {
      delete field.localId
    }
    const cleanedVariables = this.encodeSqlInputs(this.variables)
    for (let variable of cleanedVariables) {
      delete variable.localId
    }
    const cleanedMetrics = this.encodeSqlInputs(this.metrics)
    for (let metric of cleanedMetrics) {
      delete metric.localId
      if (metric.ref_event_id != null) {
        metric.ref_event_id = String(metric.ref_event_id) // cast all ref event id to string
      } else {
        delete metric.ref_event_id
      }
    }
    const cleanedRefEvents = _.cloneDeep(this.$store.getters.getRefEvents)
    for (let event of cleanedRefEvents) {
      event.id = String(event.id) // cast all as string
    }
    this.$store.dispatch('saveSourceInFeed', this.$store.getters.getCurrentSource)
    let res: any = { status: 400, response: { data: { errors: 'Failed to call Surcouche API.' } } }

    if (activate) {
      const data = {
        simulation_id: this.$store.getters.getSimulationId,
        columns: this.fileHeaders.map(x => x.value),
        nbr_leading_rows_to_skip: 1,
        feed: this.$store.getters.getCurrentFeed,
        source: this.$store.getters.getCurrentSource,
        source_id: this.$store.getters.getSavedSource.id,
        mandatory_fields: cleanedMandatoryFields,
        variables: cleanedVariables,
        metrics: cleanedMetrics,
        ref_events: cleanedRefEvents
      }
      res = await this.$apiCaller.postActivateThirdPartyFeed(data)
    } else {
      const data = {
        feed: this.$store.getters.getCurrentFeed,
        source: this.$store.getters.getCurrentSource,
        source_id: this.$store.getters.getSavedSource.id,
        mandatory_fields: cleanedMandatoryFields,
        variables: cleanedVariables,
        metrics: cleanedMetrics,
        ref_events: cleanedRefEvents
      }
      res = await this.$apiCaller.postThirdPartyTransformations(data)
    }
    if (res.status === 200) {
      this.openAlert('successSave')
      this.$store.commit('gotFeed', res.data.feed)
      this.$store.commit('setCurrentFeed', res.data.feed)
      this.$store.commit('gotSource', res.data.source)
      this.$store.commit('setCurrentSource', res.data.source)
    } else {
      this.errorMessage = res.response && res.response.data && res.response.data.errors ? res.response.data.errors : res
      this.openAlert('errorSave')
    }
  }

  createHeaderTable (strings: string[]): { text: string, value: string }[] {
    let res = []
    for (let s of strings) {
      res.push({ text: s, value: s })
    }
    return res
  }

  createLinesTable (strings: string[][]): string[][] {
    const metricsIndex = this.transformedFileHeaders.findIndex((s) => s.value === 'metrics')
    let res = [...strings]
    for (let line of res) {
      let jsonLine = line[metricsIndex]
      jsonLine.replace('\'', '"')
      let metrics = JSON.parse(jsonLine)
      let writtenLine = ''
      for (let metric of metrics) {
        writtenLine += '<b>' + metric.name + ':</b> ' + metric.value + ', '
      }
      line[metricsIndex] = writtenLine
    }
    return res
  }

  async saveAndActivate () {
    let feed = this.$store.getters.getCurrentFeed
    feed.activity_status = 'active'
    this.$store.commit('setCurrentFeed', feed)
    await this.save(true)
  }

  openAlert (alert: string) {
    switch (alert) {
      case 'successSave':
        this.successSave = true
        this.errorSave = false
        break
      case 'errorSave':
        this.successSave = false
        this.errorSave = true
        break
      case 'successSimulation':
        this.successSimulation = true
        this.errorSimulation = false
        break
      case 'errorSimulation':
        this.successSimulation = false
        this.errorSimulation = true
    }
  }

  async callGetRefEvents () {
    const partner = this.$store.getters.getCurrentFeed.third_party_partner
    const granularity = this.$store.getters.getCurrentFeed.granularity
    const res = await this.$store.dispatch('getApiRefEvents', { partner: partner, granularity: granularity })

    const data = {
      names: ['auction', 'aggregated']
    }

    await this.$store.dispatch('getApiRefGranularity', data)
    console.log(this.$store.getters.getGranularityTypes)
  }

  async openMetricsMapper () {
    await this.callGetRefEvents()
    this.metricsMapper = true
  }

  mapMetrics (metrics: []) {
    this.metrics = metrics
  }

  closeAlert (): void {
    this.successSave = false
    this.errorSave = false
    this.errorMessage = ''
  }

  closeSimulationAlert (): void {
    this.errorSimulation = false
    this.errorMessage = ''
  }

  close () {
    this.dialog = false
    this.mandatoryFields = []
    this.variables = []
    this.metrics = []
    this.transformedFileHeaders = []
    this.transformedFileLines = []
    this.closeAlert()
    this.closeSimulationAlert()
  }
}
