
import Vue from 'vue'

import ThirdPartySourceForm from '@/components/ThirdPartyFeeds/ThirdPartySourceForm.vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Dictionary } from 'vue-router/types/router'
import { ThirdPartySource, GcsBucketLocation, EmailLocation } from '../../../types/third_party_feed_types'
import { apiCaller } from '../../../store'

const NAME_FOR_TAB: Dictionary<string> = {
  'tab-0': 'Key Location Points',
  'tab-1': 'Source Management'
}

@Component({
  components: {
    ThirdPartySourceForm
  }
})

export default class ThirdPartyFeedForm extends Vue {
  @Prop({ required: false, default: false }) value: boolean
  @Prop({ required: false, default: false }) openedAsEditForm: boolean
  @Prop({ required: false, default: null }) feedId: number | null

  timeoutHandle: null | number = null
  duplicateNameWarning: boolean = false

  feedName: string = ''
  partnersList: string[] = ['doubleverify', 'ias', 'flashtalking', 'viq', 'playground_xyz', 'lasso']
  selectedPartner: string = ''
  dspList: string[] = ['appnexus', 'mediamath', 'dbm', 'thetradedesk', 'beeswax', 'youtube', 'facebook', 'kayzen']
  selectedDsp: string = ''
  memberId: string = ''
  granularityList: string[] = ['auction', 'ratio']
  selectedGranularity: string = ''
  activityList: string[] = ['active', 'paused']
  selectedActivity: string = ''
  description: string = ''
  hasBeenSaved: boolean = false
  savedId: number | null = null

  header: string = ''
  sourcesHeaders: Object[] = [
    { text: 'ID', value: 'id' },
    { text: 'Name', value: 'name' },
    { text: 'Location type', value: 'location', sortable: false },
    { text: 'Status', value: 'status' },
    { text: 'Saved', value: 'saved' },
    { text: 'Action', sortable: false }
  ]
  sources: ThirdPartySource[] = []
  successSave : boolean = false
  successAddSource: boolean = false
  lastAddedSource: string = ''
  errorSave : boolean = false
  errorMessage: string = ''
  dialogUpdated: boolean = false
  dialogUpdatedIsSave: boolean = false

  sourceIsEditForm: boolean = false
  dialogSourceForm: boolean = false
  tab: string = 'tab-0'

  get dialog (): boolean {
    return this.value
  }
  set dialog (value: boolean) {
    this.$emit('input', value)
  }
  get isEditForm (): boolean {
    return this.openedAsEditForm || this.hasBeenSaved
  }

  get formHasErrors (): boolean {
    return this.duplicateNameWarning
  }

  @Watch('value')
  async checkEditForm (value: boolean) {
    if (value) {
      if (this.isEditForm && this.feedId) {
        await this.loadFeed(this.feedId)
      } else {
        this.resetForm()
      }
    }
  }

  @Watch('$store.getters.getSavedFeed')
  reloadForm () {
    this.loadFormFromFeed()
  }

  async loadFeed (feedId: number) {
    const data = {
      feed_id: feedId,
      feed_name: 'null'
    }
    await this.$store.dispatch('getApiFeed', data)
    this.loadFormFromFeed()
  }

  loadFormFromFeed () {
    if (Object.keys(this.$store.getters.getSavedFeed).length === 0) return
    const feed = this.$store.getters.getSavedFeed
    this.feedName = feed.name ? feed.name : ''
    this.selectedPartner = feed.third_party_partner ? feed.third_party_partner : ''
    this.selectedDsp = feed.member && feed.member.dsp ? feed.member.dsp : ''
    this.memberId = feed.member && feed.member.external_id ? feed.member.external_id : ''
    this.selectedGranularity = feed.granularity ? feed.granularity : ''
    this.selectedActivity = feed.activity_status
    this.description = feed.description
    this.sources = feed.sources.map((x: ThirdPartySource) => {
      x.saved = true
      return x
    })
  }

  get idToSend (): number {
    if (!this.isEditForm) return null
    if (this.feedId) return this.feedId
    const feed = this.$store.getters.getSavedFeed
    return feed.id ? feed.id : null
  }

  onClickClose () {
    this.dialogUpdated = false
    if (this.dialogUpdatedIsSave) {
      this.saveFeed()
    } else {
      this.__closeProcess()
    }
  }

  __closeProcess () {
    this.resetForm()
    this.dialog = false
  }

  close () {
    this.dialogUpdatedIsSave = false
    if (this.checkForChanges()) {
      this.dialogUpdated = true
    } else {
      this.onClickClose()
    }
  }

  save () {
    this.dialogUpdatedIsSave = true
    this.onClickClose()
  }

  cancelDialogUpdated () {
    this.dialogUpdated = false
  }
  openNewSourceForm () {
    this.saveFeedInStore()
    this.sourceIsEditForm = false
    this.dialogSourceForm = true
  }

  openAlert (alert: string) {
    switch (alert) {
      case 'successSave':
        this.successSave = true
        this.successAddSource = false
        this.errorSave = false
        break
      case 'successAddSource':
        this.successSave = false
        this.successAddSource = true
        this.errorSave = false
        break
      case 'errorSave':
        this.successSave = false
        this.successAddSource = false
        this.errorSave = true
    }
  }

  openSourceEditForm (source: ThirdPartySource) {
    this.saveFeedInStore()
    this.$store.commit('gotSource', source)
    this.sourceIsEditForm = true
    this.dialogSourceForm = true
  }

  searchBufferizeFeedName () {
    if (this.timeoutHandle !== null) {
      clearTimeout(this.timeoutHandle)
    }

    this.timeoutHandle = window.setTimeout(() => {
      this.$nextTick(async () => {
        await this.getFeedNameExists()
      })
    }, 400)
  }

  async getFeedNameExists () {
    const data = {
      feed_name: this.feedName,
      feed_id: this.feedId
    }
    const result = await this.$apiCaller.getThirdPartyFeed(data)
    if (apiCaller.isResponseError(result)) {
      this.duplicateNameWarning = false
    } else {
      this.duplicateNameWarning = result.data
    }
  }

  saveFeedInStore () {
    const data = {
      edited_feed_id: this.idToSend,
      third_party_partner: this.selectedPartner,
      activity_status: this.selectedActivity,
      description: this.description,
      granularity: this.selectedGranularity,
      dsp: this.selectedDsp,
      member_id: this.memberId,
      name: this.feedName,
      sources: this.sources
    }

    this.$store.commit('setCurrentFeed', data)
  }

  async saveFeed () {
    this.saveFeedInStore()
    const data = this.$store.getters.getCurrentFeed
    const response = await this.$apiCaller.postThirdPartyFeed(data)
    if (this.$apiCaller.isResponseError(response)) {
      this.openAlert('errorSave')
      this.errorMessage = response.response && response.response.data && response.response.data.errors
        ? response.response.data.errors
        : response
    } else {
      this.hasBeenSaved = true
      this.savedId = response.data
      await this.loadFeed(this.savedId)
      this.loadFormFromFeed()
      this.openAlert('successSave')
      this.$emit('saved')
    }
  }

  get sourcesToSave (): ThirdPartySource[] {
    return this.sources.filter(x => x.saved === false)
  }

  getNameForTab (tab: string): string {
    return NAME_FOR_TAB[tab]
  }

  saveSource (source: ThirdPartySource): void {
    this.$store.dispatch('saveSourceInFeed', source)
    // force refresh sources table
    this.sources = this.$store.getters.getCurrentFeed.sources
    this.openAlert('successAddSource')
    this.lastAddedSource = this.getSourceName(source)
  }

  getSourceName (source: ThirdPartySource): string {
    const location = source.location
    if (this.isLocationBucket(location)) {
      return location.bucket_name
    } else if (this.isLocationEmail(location)) {
      return location.mail
    }
    return 'unnamed'
  }

  isLocationBucket (location: any): location is GcsBucketLocation {
    return 'bucket_name' in location
  }

  isLocationEmail (location: any): location is EmailLocation {
    return 'email' in location
  }

  resetForm (): void {
    this.$store.commit('setCurrentFeed', {})
    this.closeAlert()
    this.hasBeenSaved = false
    this.savedId = null
    this.feedName = ''
    this.selectedPartner = ''
    this.selectedDsp = ''
    this.memberId = ''
    this.description = ''
    this.selectedGranularity = ''
    this.selectedActivity = ''
    this.sources = []
    this.lastAddedSource = ''
    this.$store.commit('gotFeed', {})
    this.$store.commit('gotSources', [])
  }

  checkForChanges (): boolean {
    const emptyFeed = {
      name: '',
      third_party_partner: '',
      member: {
        dsp: '',
        external_id: ''
      },
      granularity: '',
      description: ''
    }
    const savedFeed = Object.keys(this.$store.getters.getSavedFeed).length > 0 ? this.$store.getters.getSavedFeed : emptyFeed
    if (
      (savedFeed.name !== this.feedName) ||
      (savedFeed.third_party_partner !== this.selectedPartner) ||
      (savedFeed.member && savedFeed.member.dsp !== this.selectedDsp) ||
      (savedFeed.member && savedFeed.member.external_id !== this.memberId) ||
      (savedFeed.granularity !== this.selectedGranularity) ||
      (savedFeed.description !== this.description) ||
      (this.sourcesToSave.length > 0)
    ) {
      return true
    }
    return false
  }

  closeAlert (): void {
    this.successSave = false
    this.errorSave = false
    this.successAddSource = false
  }
}
