
import { Vue, Component, Prop } from 'vue-property-decorator'
import { isNotNullOrUndefined } from '../../../utils/commonUtils'

@Component({})
export default class NewRefEventForm extends Vue {
  @Prop({ required: false, default: false }) value: boolean
  @Prop({ required: true }) metricLocalId: number

  // eslint-disable-next-line camelcase
  selectedEventType: string = ''
  selectedRange: string = ''

  get refEventTypes (): [] {
    return this.$store.getters.getRefEventTypes.map((x: any) => x.event_type)
  }

  get auctionId (): number {
    const auction = this.$store.getters.getGranularityTypes.find((x: any) => x.granularity === 'auction')
    if (isNotNullOrUndefined(auction)) {
      return auction.id
    }
    return null
  }

  get ratioId (): number {
    const ratio = this.$store.getters.getGranularityTypes.find((x: any) => x.granularity === 'aggregated')
    if (isNotNullOrUndefined(ratio)) {
      return ratio.id
    }
    return null
  }

  get dialog (): boolean {
    return this.value
  }

  set dialog (value: boolean) {
    this.$emit('input', value)
  }

  close () {
    this.dialog = false
  }

  saveRefEvent () {
    const ratio = this.$store.getters.getGranularityTypes.find((x: any) => x.granularity === 'aggregated')
    const event = {
      event_name: this.buildEventName(),
      event_type_name: this.selectedEventType,
      event_value_range: this.selectedRange,
      granularity_id: this.$store.getters.getCurrentFeed.granularity === 'auction' ? this.auctionId : this.ratioId,
      has_pixel_id: 0,
      id: 'NEW' + this.$store.getters.getLocalRefEventId,
      source: this.$store.getters.getCurrentFeed.third_party_partner,
      source_type: '3rd_party'
    }
    this.$store.commit('incrementLocalRefEventId')
    this.$store.dispatch('saveNewRefEvent', event)
    this.$emit('save-new-event', this.metricLocalId, event.id)
    this.close()
  }

  buildEventName () {
    const partner = this.$store.getters.getCurrentFeed.third_party_partner
    const granularity = this.$store.getters.getCurrentFeed.granularity === 'auction' ? 'auction' : 'aggregated'
    return `3rd_party_${partner}_${this.selectedEventType}_${granularity}`
  }
}
