
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import NewRefEventForm from '@/components/ThirdPartyFeeds/NewRefEventForm.vue'
import _ from 'lodash'

@Component({
  components: { NewRefEventForm }
})
export default class MetricsMapper extends Vue {
  @Prop({ required: false, default: false }) value: boolean
  @Prop({ required: true }) metrics: []

  metricsProxy: []
  refEventDialog = false
  openMapMetricLocalId: number = null

  get dialog (): boolean {
    return this.value
  }

  @Watch('value')
  onOpen (val: boolean) {
    if (val) {
      this.initializeProxy()
    }
  }

  initializeProxy () {
    this.metricsProxy = _.cloneDeep(this.metrics)
  }

  set dialog (value: boolean) {
    this.$emit('input', value)
  }

  get loadingRefEventForm (): boolean {
    return this.$store.getters.getLoadingRefEventTypes
  }

  get refEvents () {
    return this.$store.getters.getRefEvents.map((x: any) => {
      return { text: x.event_name, value: x.id }
    })
  }

  get refEventFullItems () {
    return this.$store.getters.getRefEvents
  }

  async openNewRefEventForm (id: number) {
    await this.$store.dispatch('getApiRefEventTypes')
    console.log(this.$store.getters.getGranularityTypes)
    this.openMapMetricLocalId = id
    this.refEventDialog = true
  }

  setSelectedEventForMetric (metricLocalId: number, eventId: string) {
    let item: any = this.metricsProxy.find((x: any) => x.localId === metricLocalId)
    item.ref_event_id = eventId
  }

  saveMapping () {
    this.$emit('save', this.metricsProxy)
    this.close()
  }

  close () {
    this.dialog = false
  }
}
