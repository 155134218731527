
/* eslint-disable camelcase */
import { Component, Prop, Watch } from 'vue-property-decorator'
import { rulesMixin } from '@/mixins/rulesMixin'
import {
  CompressionModel,
  Compressions, CsvFileFormat, EmailLocation,
  ExcelFileFormat, FileModel,
  Formats,
  GcsBucketLocation,
  Locations,
  ThirdPartySource
} from '../../../types/third_party_feed_types'
import Vue from 'vue'
import ThirdPartyTransformations from '@/components/ThirdPartyFeeds/ThirdPartyTransformations.vue'
import { isNotNullOrUndefined } from '../../../utils/commonUtils'

@Component({
  components: { ThirdPartyTransformations },
  mixins: [rulesMixin]
})

export default class ThirdPartySourceForm extends Vue {
  @Prop({ required: false, default: false }) value: boolean
  @Prop({ required: false, default: false }) openedAsEditForm: boolean
  @Prop({ required: false, default: null }) sourceToEdit: ThirdPartySource | null

  valid: boolean = false
  hasBeenSaved: boolean = false
  locationTypes: any[] = [
    { text: 'Scibids GCS Bucket', value: Locations.BUCKET },
    { text: 'Email', value: Locations.EMAIL }
  ]
  selectedLocationType: string = ''
  bucketName: string = ''
  prefixPath: string = ''
  email: string = ''
  emailSearchRequest: string = ''
  filenameRegex: string = ''
  compressionTypes: any[] = [
    { text: 'none', value: Compressions.NONE },
    { text: 'zip', value: Compressions.ZIP },
    { text: 'gz', value: Compressions.GZIP }
  ]
  selectedCompression: string = ''
  zipRegex: string = ''
  formatTypes: string[] = Object.values(Formats)
  selectedFormat: string = ''
  separator: string = ''
  beginningCell: string = ''
  sheet: string = ''

  dialogUpdated: boolean = false

  errorAddSource: boolean = false
  errorSourceMessage: string = ''

  selectedFile: any = {}
  openedFile: any = {}

  bucketHeaders: any[] = [
    { text: 'File name', value: 'file_name' },
    { text: 'Creation date', value: 'creation_date' },
    { text: 'Size', value: 'size' }
  ]

  dialogTransformations: boolean = false

  get LocationsEnum (): typeof Locations {
    return Locations
  }

  get CompressionsEnum (): typeof Compressions {
    return Compressions
  }

  get FormatsEnum (): typeof Formats {
    return Formats
  }

  get locationContent (): any[] {
    return this.$store.getters.getLocationContent
  }

  get fileContent (): { header: string[], lines: string[][] } {
    return this.$store.getters.getFileContent
  }

  get fileHeaders (): { text: string, value: string }[] {
    return this.fileContent.lines.length > 0 ? this.fileContent.lines[0].map(x => ({ text: x, value: x })) : []
  }

  get fileLines (): any[] {
    if (this.fileContent.lines.length === 0) return []
    let lines = [...this.fileContent.lines]
    lines.shift()
    return lines
  }

  get dialog (): boolean {
    return this.value
  }

  set dialog (value: boolean) {
    this.$emit('input', value)
  }

  get isEditForm (): boolean {
    return this.openedAsEditForm || this.hasBeenSaved
  }

  get loadingTransformations (): boolean {
    return this.$store.getters.getLoadingSetupTransformations
  }

  mounted () {
    this.resetStoredFiles()
  }

  buildLocation (): EmailLocation | GcsBucketLocation {
    let location: EmailLocation | GcsBucketLocation

    switch (this.selectedLocationType) {
      case Locations.BUCKET:
        location = {
          location_type: this.selectedLocationType,
          bucket_name: this.bucketName,
          prefix_filename: this.prefixPath,
          regex_filename_validation: this.filenameRegex
        }
        break
      case Locations.EMAIL:
        location = {
          location_type: this.selectedLocationType,
          mail: this.email,
          search_request: this.emailSearchRequest,
          regex_filename_validation: this.filenameRegex
        }
        break
      default:
        location = {
          location_type: this.selectedLocationType,
          bucket_name: this.bucketName,
          prefix_filename: this.prefixPath,
          regex_filename_validation: this.filenameRegex
        }
    }
    return location
  }

  buildFileModel (): FileModel {
    // COMPRESSION
    let compression: CompressionModel = {
      type: this.selectedCompression
    }
    if (this.selectedCompression === Compressions.ZIP) {
      compression.file_regexp = this.zipRegex
    }

    // FILE FORMAT
    let file_format: CsvFileFormat | ExcelFileFormat
    switch (this.selectedFormat) {
      case Formats.CSV:
        file_format = {
          separator: this.separator !== '' ? this.separator : ','
        }
        break
      case Formats.EXCEL:
        file_format = {
          beginning_cell: this.beginningCell !== '' ? this.beginningCell : 'A1',
          sheet: this.sheet !== '' ? this.sheet : 'Sheet1'
        }
        break
      default:
        file_format = {
          separator: ','
        }
    }

    return { compression, file_format }
  }

  saveSource () {
    this.saveSourceInStore()
    const source: ThirdPartySource = this.$store.getters.getCurrentSource
    this.$emit('save', source)
    this.onClickClose()
  }

  get showLocationContent (): boolean {
    return this.$store.getters.getLoadingLocationContent || this.locationContent.length > 0
  }

  get showLoadingLocation (): boolean {
    return this.$store.getters.getLoadingLocationContent
  }

  async getLocationContent (): Promise<void> {
    switch (this.selectedLocationType) {
      case Locations.BUCKET:
        await this.getBucketContent()
        return
      case Locations.EMAIL:
        await this.getEmailContent()
    }
  }

  async getBucketContent (): Promise<void> {
    const data = {
      location_type: this.selectedLocationType,
      bucket_name: this.bucketName,
      prefix_filename: this.prefixPath,
      regex_filename_validation: this.filenameRegex
    }
    await this.$store.dispatch('getApi3pLocation', data)
  }

  async getEmailContent (): Promise<void> {
    const data = {
      location_type: this.selectedLocationType,
      mail: this.email,
      search_request: this.emailSearchRequest,
      regex_filename_validation: this.filenameRegex
    }
    await this.$store.dispatch('getApi3pLocation', data)
  }

  selectFile (file: any): void {
    this.selectedFile = this.selectedFile === file ? {} : file
  }

  async openFile (): Promise<void> {
    const data = {
      file_to_download: this.selectedFile,
      file_model: this.buildFileModel()
    }
    this.openedFile = this.selectedFile
    await this.$store.dispatch('getApi3pFile', data)
  }

  get showFileSection (): boolean {
    return (Object.keys(this.selectedFile).length > 0) ||
      (this.showFileContents)
  }

  get showFileContents (): boolean {
    return this.$store.getters.getLoadingFileContent || this.fileContent.lines.length > 0
  }

  get successfulOpenFile (): boolean {
    return this.fileContent.lines.length > 0
  }

  get showLoadingFile (): boolean {
    return this.$store.getters.getLoadingFileContent
  }

  @Watch('value')
  checkEditForm (value: boolean) {
    if (value) {
      if (this.isEditForm && Object.keys(this.$store.getters.getSavedSource).length > 0) {
        this.loadFormFromSource()
      }
    } else {
      this.resetSourceForm()
    }
  }

  loadFormFromSource () {
    const source = this.$store.getters.getSavedSource
    this.selectedLocationType = source.location.location_type
    this.filenameRegex = source.location.regex_filename_validation
    if (this.isLocationBucket(source.location)) {
      this.bucketName = source.location.bucket_name
      this.prefixPath = source.location.prefix_filename
    } else if (this.isLocationEmail(source.location)) {
      this.email = source.location.mail
      this.emailSearchRequest = source.location.search_request
    }
    this.selectedCompression = source.file_model.compression.type
    this.zipRegex = source.file_model.compression.file_regexp !== undefined ? source.file_model.compression.file_regexp : ''
    if (this.isFormatExcel(source.file_model.file_format)) {
      this.selectedFormat = Formats.EXCEL
      this.sheet = source.file_model.file_format.sheet
      this.beginningCell = source.file_model.file_format.beginning_cell
    } else if (this.isFormatCsv(source.file_model.file_format)) {
      this.selectedFormat = Formats.CSV
      this.separator = source.file_model.file_format.separator
    }
  }

  isLocationBucket (location: any): location is GcsBucketLocation {
    return location && 'bucket_name' in location
  }

  isLocationEmail (location: any): location is EmailLocation {
    return location && 'email' in location
  }

  isFormatExcel (format: any): format is ExcelFileFormat {
    return format && 'sheet' in format
  }
  isFormatCsv (format: any): format is CsvFileFormat {
    return format && 'separator' in format
  }

  close () {
    if (this.checkForChanges()) {
      this.dialogUpdated = true
    } else {
      this.onClickClose()
    }
  }

  onClickClose () {
    this.__closeProcess()
  }

  __closeProcess () {
    this.resetSourceForm()
    this.dialog = false
    this.cancelDialogUpdated()
  }

  getFileSize (size: number): string {
    if (size < 1000) return size + ' B'
    else if (size >= 1000 && size < 1000000) return (size / 1000) + ' KB'
    else if (size >= 1000000 && size < 1000000000) return (size / 1000000) + ' MB'
    else return (size / 1000000000) + ' GB'
  }

  checkForChanges (): boolean {
    const empty: ThirdPartySource = {
      location: {
        location_type: '',
        bucket_name: '',
        prefix_filename: '',
        regex_filename_validation: ''
      },
      file_model: {
        compression: {
          type: ''
        },
        file_format: {
          separator: ''
        }
      },
      status: '',
      saved: true
    }
    const baseSource = Object.keys(this.$store.getters.getSavedSource).length > 0 ? this.$store.getters.getSavedSource : empty
    // location
    if (this.isLocationBucket(baseSource.location)) {
      if (
        (baseSource.location.location_type !== this.selectedLocationType) ||
        (baseSource.location.bucket_name !== this.bucketName) ||
        (baseSource.location.prefix_filename !== this.prefixPath) ||
        (baseSource.location.regex_filename_validation !== this.filenameRegex)
      ) return true
    } else if (this.isLocationEmail(baseSource.location)) {
      if (
        (baseSource.location.location_type !== this.selectedLocationType) ||
        (baseSource.location.regex_filename_validation !== this.filenameRegex) ||
        (baseSource.location.mail !== this.email) ||
        (baseSource.location.search_request !== this.emailSearchRequest)
      ) return true
    }
    // compression
    if (
      (baseSource.file_model.compression.type !== this.selectedCompression) ||
      ((baseSource.file_model.compression.file_regexp !== undefined) && (baseSource.file_model.compression.file_regexp !== this.zipRegex)) ||
      ((baseSource.file_model.compression.file_regexp === undefined) && (this.zipRegex !== ''))
    ) {
      return true
    }

    // format
    if (this.isFormatCsv(baseSource.file_model.file_format)) {
      if (baseSource.file_model.file_format.separator !== this.separator) return true
    } else if (this.isFormatExcel(baseSource.file_model.file_format)) {
      if (
        (baseSource.file_model.file_format.sheet !== this.sheet) ||
        (baseSource.file_model.file_format.beginning_cell !== this.beginningCell)
      ) return true
    }

    return false
  }

  @Watch('$store.getters.getSavedSource')
  reloadForm () {
    this.loadFormFromSource()
  }

  closeAlert () {
    this.errorAddSource = false
  }

  cancelDialogUpdated () {
    this.dialogUpdated = false
  }

  saveSourceInStore () {
    let id: number | undefined
    let localId: number | undefined
    if (this.isEditForm && this.$store.getters.getSavedSource) {
      id = this.$store.getters.getSavedSource.id
      localId = this.$store.getters.getSavedSource.localId
    }
    const source: ThirdPartySource = {
      id,
      localId,
      location: this.buildLocation(),
      file_model: this.buildFileModel(),
      status: 'draft',
      saved: false
    }
    this.$store.commit('setCurrentSource', source)
  }

  async openTransformations () {
    this.saveSourceInStore()
    await this.callCreateSimulationEndpoint()
    this.dialogTransformations = true
  }

  get canOpenTransformation (): boolean {
    const feed = this.$store.getters.getCurrentFeed
    return (
      (Object.keys(feed).length > 0) &&
      (isNotNullOrUndefined(feed.dsp)) &&
      (feed.dsp !== '') &&
      (isNotNullOrUndefined(feed.granularity)) &&
      (feed.granularity !== '') &&
      (this.successfulOpenFile)
    )
  }

  async callCreateSimulationEndpoint () {
    const file_to_download = this.openedFile
    const file_model = {
      ...this.buildFileModel(),
      columns: this.fileHeaders.map(x => x.value),
      nbr_leading_rows_to_skip: 1
    }
    let data: any = {
      file_model,
      file_to_download,
      dsp: this.$store.getters.getCurrentFeed.dsp,
      granularity: this.$store.getters.getCurrentFeed.granularity
    }
    if (this.$store.getters.getCurrentSource.id) {
      data = { ...data, source_id: this.$store.getters.getCurrentSource.id }
    }
    const res = await this.$store.dispatch('getApi3pTransformationId', data)
  }

  resetSourceForm (): void {
    this.$store.commit('setCurrentSource', {})
    const form = (this.$refs.form as HTMLFormElement)
    form.reset()
    this.closeAlert()
    this.hasBeenSaved = false
    this.resetLocationForm()
    this.resetFileForm()
    this.resetStoredFiles()
    this.selectedFile = {}
  }

  resetLocationForm (): void {
    this.selectedLocationType = ''
    this.filenameRegex = ''
    this.resetMailForm()
    this.resetGcsForm()
  }

  resetFileForm (): void {
    this.selectedCompression = ''
    this.selectedFormat = ''
    this.separator = ''
    this.beginningCell = ''
    this.zipRegex = ''
  }

  resetGcsForm (): void {
    this.bucketName = ''
    this.prefixPath = ''
  }

  resetMailForm (): void {
    this.email = ''
    this.emailSearchRequest = ''
  }

  resetStoredFiles (): void {
    this.$store.commit('gotLocationContent', [])
    this.$store.commit('gotFileContent', { header: [], lines: [] })
  }
}
