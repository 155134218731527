
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { rulesMixin } from '@/mixins/rulesMixin'

@Component({
  components: {},
  mixins: [rulesMixin]
})

export default class MergedValueModule extends Vue {
  @Prop({ required: false, default: null }) index: number
  @Prop({ required: false, default: false }) columns: string[]
  @Prop({ required: false, default: null }) defaultValue: any

  value: Object = { type_of_value: '' }
  timeoutHandle: any = null

  directInputTypes: any[] = [
    { text: 'Input Fixed Value', value: 'FIXED' },
    { text: 'Direct Column', value: 'COLUMN' },
    { text: 'Regexp on Column', value: 'REGEXP' },
    { text: 'Regexp on Filename', value: 'FILENAME' }
  ]

  mounted () {
    this.initializeValue(this.defaultValue)
  }

  initializeValue (value: any) {
    this.value = this.defaultValue
  }

  @Watch('value', { deep: true })
  onValueChange (value: any) {
    if (this.timeoutHandle !== null) {
      clearTimeout(this.timeoutHandle)
    }
    this.timeoutHandle = window.setTimeout(() => {
      this.$emit('update-value', this.index, value)
    }, 1000)
  }
}
