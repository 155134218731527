/* eslint-disable camelcase */

export enum Locations {
  BUCKET = 'BUCKET',
  EMAIL = 'EMAIL'
}

export enum Formats {
  CSV = 'csv',
  EXCEL = 'excel'
}

export enum Compressions {
  ZIP = 'ZIP',
  GZIP = 'GZIP',
  NONE = 'NO_COMPRESSION'
}

export enum TimezoneFields {
  GMTMinus14 = 'Etc/GMT-14',
  GMTMinus13 = 'Etc/GMT-13',
  GMTMinus12 = 'Etc/GMT-12',
  GMTMinus11 = 'Etc/GMT-11',
  GMTMinus10 = 'Etc/GMT-10',
  GMTMinus9 = 'Etc/GMT-9',
  GMTMinus8 = 'Etc/GMT-8',
  GMTMinus7 = 'Etc/GMT-7',
  GMTMinus6 = 'Etc/GMT-6',
  GMTMinus5 = 'Etc/GMT-5',
  GMTMinus4 = 'Etc/GMT-4',
  GMTMinus3 = 'Etc/GMT-3',
  GMTMinus2 = 'Etc/GMT-2',
  GMTMinus1 = 'Etc/GMT-1',
  GMT = 'Etc/GMT+0',
  GMTPlus1 = 'Etc/GMT+1',
  GMTPlus2 = 'Etc/GMT+2',
  GMTPlus3 = 'Etc/GMT+3',
  GMTPlus4 = 'Etc/GMT+4',
  GMTPlus5 = 'Etc/GMT+5',
  GMTPlus6 = 'Etc/GMT+6',
  GMTPlus7 = 'Etc/GMT+7',
  GMTPlus8 = 'Etc/GMT+8',
  GMTPlus9 = 'Etc/GMT+9',
  GMTPlus10 = 'Etc/GMT+10',
  GMTPlus11 = 'Etc/GMT+11',
  GMTPlus12 = 'Etc/GMT+12'
}

export interface GcsBucketLocation {
  location_type: string,
  bucket_name: string,
  prefix_filename: string,
  regex_filename_validation: string
}

export interface EmailLocation {
  location_type: string,
  mail: string,
  search_request: string,
  regex_filename_validation: string
}

export interface CompressionModel {
  type: string,
  file_regexp?: string
}

export interface CsvFileFormat {
  separator: string
}

export interface ExcelFileFormat {
  beginning_cell: string
  sheet: string
}

export interface FileModel {
  compression: CompressionModel,
  file_format: ExcelFileFormat | CsvFileFormat
}

export interface ThirdPartyFeedInList {
  id: number
  activity_status: string
  third_party_partner: string
  nb_sources: number
  dsp: string,
  external_id: string,
  name: string,
  description: string
}

export interface ThirdPartySource {
  id?: number,
  location: GcsBucketLocation | EmailLocation,
  file_model: FileModel,
  status: string,
  saved: boolean,
  localId?: number
}
