import { apiCaller, Vue } from '../index'
import { Locations, ThirdPartySource } from '../../types/third_party_feed_types'
import { isNotNullOrUndefined } from '../../utils/commonUtils'

const state = {
  feeds: [],
  loadingFeeds: false,
  savedFeed: {},
  loadingFeed: false,
  currentFeed: {},
  sources: [],
  loadingSources: [],
  savedSource: {},
  currentSource: {},
  loadingSource: false,
  locationContent: [],
  loadingLocationContent: false,
  fileContent: { header: [], lines: [] },
  loadingFileContent: false,
  mandatoryFieldsNames: [],
  niceToHaveFieldsNames: [],
  variablesNames: [],
  metricsNames: [],
  mandatoryFields: [],
  variables: [],
  metrics: [],
  loadingSetupTransformations: false,
  simulationId: '',
  localVariableId: 0,
  localMandatoryFieldId: 0,
  localMetricId: 0,
  refEvents: [],
  loadingRefEvents: false,
  refEventTypes: [],
  loadingRefEventTypes: false,
  localRefEventId: 0,
  granularityTypes: []
}

const actions = {
  async getApi3pFeeds ({ commit, state }, args) {
    commit('setLoadingFeeds', true)

    const result = await apiCaller.getThirdPartyFeeds()

    if (apiCaller.isResponseError(result)) {
      commit('setErrorMessageWithResponse', result)
      commit('gotFeeds', [])
    } else {
      commit('gotFeeds', result.data)
    }
    commit('setLoadingFeeds', false)
  },

  async getApiFeed ({ commit, state }, args) {
    commit('setLoadingFeed', true)
    commit('setLoadingSources', true)
    const result = await apiCaller.getThirdPartyFeed(args)
    if (apiCaller.isResponseError(result)) {
      commit('setErrorMessageWithResponse', result)
      commit('gotFeed', {})
      commit('gotSources', [])
    } else {
      commit('gotFeed', result.data)
      if (result.data.sources) {
        commit('gotSources', result.data.sources)
      }
    }
    commit('setLoadingFeed', false)
    commit('setLoadingSources', false)
  },

  async getApi3pLocation ({ commit, state }, args) {
    commit('setLoadingLocationContent', true)
    let result = { status: 400, data: { errors: 'Please select a valid location type.' } }

    result = await apiCaller.getThirdPartyLocation(args)
    if (apiCaller.isResponseError(result)) {
      commit('setErrorMessageWithResponse', result)
      commit('gotLocationContent', [])
    } else {
      const deserialized = JSON.parse(result.data)
      if (Array.isArray(deserialized)) {
        commit('gotLocationContent', deserialized)
      } else {
        const error = 'There was an error while parsing the location\'s contents.'
        commit('setErrorMessageWithResponse', error)
        commit('gotLocationContent', [])
      }
    }
    commit('setLoadingLocationContent', false)
  },

  async getApi3pFile ({ commit, state }, args) {
    commit('setLoadingFileContent', true)
    const result = await apiCaller.postThirdPartyFile(args)
    if (apiCaller.isResponseError(result)) {
      commit('setErrorMessageWithResponse', result)
      commit('gotFileContent', { header: [], lines: [] })
    } else {
      const deserialized = JSON.parse(result.data)
      try {
        commit('gotFileContent', deserialized)
      } catch (e) {
        const error = 'There was an error while parsing the file\'s contents.'
        commit('setErrorMessageWithResponse', error)
        commit('gotFileContent', { header: [], lines: [] })
      }
    }
    commit('setLoadingFileContent', false)
  },

  async getApi3pTransformationId ({ commit, state }, args) {
    commit('setLoadingSetupTransformations', true)
    let mandatoryFields = []
    let mandatoryFieldsNames = []
    let niceToHaveFieldsNames = []
    let metrics = []
    let metricsNames = []
    let pivotVariables = []
    let pivotVariablesNames = []
    commit('setLocalMandatoryFieldId', 0)
    commit('setLocalMetricId', 0)
    commit('setLocalVariableId', 0)
    const res = await apiCaller.postSetupTransformations(args)
    if (apiCaller.isResponseError(res)) {
      commit('setErrorMessageWithResponse', res)
      commit('gotSimulationId', '')
    } else {
      commit('gotSimulationId', res.data.simulation_id)
      if (res.data.mandatory_variables) {
        for (const variable of res.data.mandatory_variables) {
          // fill mandatory fields with empty ones with the right names
          mandatoryFields.push({
            name: variable.name,
            type_of_rule: '',
            values: [
              {
                type_of_value: '',
                value: ''
              }
            ],
            mandatory: (variable.mandatory_level === 'mandatory'),
            localId: state.localMandatoryFieldId
          })
          commit('setLocalMandatoryFieldId', state.localMandatoryFieldId + 1)
        }
        mandatoryFieldsNames = mandatoryFields.map((x) => x.name)
        niceToHaveFieldsNames = mandatoryFields.map((x) => { if (!x.mandatory) return x.name })

        // if there are saved ones, clear then fill with saved
        if (res.data.saved_mandatory_variables) {
          mandatoryFields = []
          commit('setLocalMandatoryFieldId', 0)
          for (const savedVariable of res.data.saved_mandatory_variables) {
            mandatoryFields.push({ ...savedVariable.transformation, localId: state.localMandatoryFieldId })
            commit('setLocalMandatoryFieldId', state.localMandatoryFieldId + 1)
          }
        }
        for (let field of mandatoryFields) {
          // if field name is in mandatory but not in nice to have, force set to mandatory
          if ((mandatoryFieldsNames.includes(field.name)) && (!niceToHaveFieldsNames.includes(field.name))) {
            field.mandatory = true
          } else if (niceToHaveFieldsNames.includes(field.name)) {
            field.mandatory = false
          }
        }
      } else {
        // error
        console.warn('Could not get mandatory fields')
      }
      if (res.data.metrics) {
        for (const metric of res.data.metrics) {
          metricsNames.push(metric.name)
        }
        if (res.data.saved_metrics) {
          for (const savedMetric of res.data.saved_metrics) {
            const transformation = {
              ...savedMetric.transformation,
              localId: state.localMetricId,
              ref_event_id: isNotNullOrUndefined(savedMetric.ref_event) ? savedMetric.ref_event.id : ''
            }
            metrics.push(transformation)
            commit('setLocalMetricId', state.localMetricId + 1)
          }
        }
      } else {
        console.warn('Could not get metrics')
      }
      if (res.data.pivot_variables) {
        for (const pivot of res.data.pivot_variables) {
          pivotVariablesNames.push(pivot.variable)
        }
        if (res.data.saved_pivot_variables) {
          for (const savedPivot of res.data.saved_pivot_variables) {
            const transformation = {
              ...savedPivot.transformation,
              localId: state.localVariableId
            }
            pivotVariables.push(transformation)
            commit('setLocalVariableId', state.localVariableId + 1)
          }
        }
      }
    }

    commit('gotMandatoryFieldsNames', mandatoryFieldsNames)
    commit('gotMandatoryFields', mandatoryFields)
    commit('gotNiceToHaveFieldsNames', niceToHaveFieldsNames)
    commit('gotMetrics', metrics)
    commit('gotMetricsNames', metricsNames)
    commit('gotVariables', pivotVariables)
    commit('gotVariablesNames', pivotVariablesNames)
    commit('setLoadingSetupTransformations', false)
    return res
  },

  async getApiRefGranularity ({ commit, state }, args) {
    const res = await apiCaller.getThirdPartyRefGranularity(args)
    if (apiCaller.isResponseError(res)) {
      commit('setErrorMessageWithResponse', res)
      commit('gotRefGranularityTypes', [])
    } else {
      console.log(res)
      commit('gotRefGranularityTypes', res.data)
    }
  },

  async getApiRefEvents ({ commit, state }, args) {
    commit('setLoadingRefEvents', true)
    const res = await apiCaller.getThirdPartyRefEvents(args)
    if (apiCaller.isResponseError(res)) {
      commit('setErrorMessageWithResponse', res)
      commit('gotRefEvents', [])
    } else {
      commit('gotRefEvents', res.data)
    }
    commit('setLoadingRefEvents', false)
  },

  async getApiRefEventTypes ({ commit, state }) {
    commit('setLoadingRefEventTypes', true)
    const res = await apiCaller.getThirdPartyRefEventTypes()
    if (apiCaller.isResponseError(res)) {
      commit('setErrorMessageWithResponse', res)
      commit('gotRefEventTypes', [])
    } else {
      commit('gotRefEventTypes', res.data)
    }
    commit('setLoadingRefEventTypes', false)
  },

  saveSourceInFeed ({ commit, state }, source) {
    // assign local id if new source, in case we want to edit it
    if (source.id === undefined && source.localId === undefined) {
      source.localId = Math.random()
      source.id = null
    }
    let feed = state.currentFeed
    let sources = [...feed.sources]
    const sourceById = feed.sources.findIndex((s) => s.id === source.id)
    const sourceByLocalId = feed.sources.findIndex((s) => s.localId === source.localId)

    if (sourceById > -1) {
      sources[sourceById] = source
    } else if (sourceByLocalId > -1) {
      sources[sourceByLocalId] = source
    } else {
      sources.push(source)
    }
    // force refresh sources table
    feed.sources = sources
    commit('setCurrentFeed', feed)
  },

  saveNewRefEvent ({ commit, state }, event) {
    let events = [...state.refEvents]
    events.push(event)
    commit('gotRefEvents', events)
  }
}

const mutations = {
  gotFeeds (state, feeds) {
    Vue.set(state, 'feeds', feeds)
  },
  setLoadingFeeds (state, loading) {
    Vue.set(state, 'loadingFeeds', loading)
  },
  gotFeed (state, feed) {
    Vue.set(state, 'savedFeed', feed)
  },
  setLoadingFeed (state, loading) {
    Vue.set(state, 'loadingFeed', loading)
  },
  setCurrentFeed (state, feed) {
    Vue.set(state, 'currentFeed', feed)
  },
  gotSources (state, sources) {
    Vue.set(state, 'sources', sources)
  },
  setLoadingSources (state, loading) {
    Vue.set(state, 'loadingSources', loading)
  },
  gotSource (state, source) {
    Vue.set(state, 'savedSource', source)
  },
  setLoadingSource (state, loading) {
    Vue.set(state, 'loadingSource', loading)
  },
  setCurrentSource (state, source) {
    Vue.set(state, 'currentSource', source)
  },
  gotLocationContent (state, content) {
    Vue.set(state, 'locationContent', content)
  },
  setLoadingLocationContent (state, loading) {
    Vue.set(state, 'loadingLocationContent', loading)
  },
  gotFileContent (state, file) {
    Vue.set(state, 'fileContent', file)
  },
  setLoadingFileContent (state, loading) {
    Vue.set(state, 'loadingFileContent', loading)
  },
  setLoadingSetupTransformations (state, loading) {
    Vue.set(state, 'loadingSetupTransformations', loading)
  },
  gotMandatoryFieldsNames (state, list) {
    Vue.set(state, 'mandatoryFieldsNames', list)
  },
  gotNiceToHaveFieldsNames (state, list) {
    Vue.set(state, 'niceToHaveFieldsNames', list)
  },
  gotVariablesNames (state, list) {
    Vue.set(state, 'variablesNames', list)
  },
  gotMetricsNames (state, list) {
    Vue.set(state, 'metricsNames', list)
  },
  gotMandatoryFields (state, list) {
    Vue.set(state, 'mandatoryFields', list)
  },
  gotVariables (state, list) {
    Vue.set(state, 'variables', list)
  },
  gotMetrics (state, list) {
    Vue.set(state, 'metrics', list)
  },
  gotSimulationId (state, id) {
    Vue.set(state, 'simulationId', id)
  },
  setLocalVariableId (state, id) {
    Vue.set(state, 'localVariableId', id)
  },
  setLocalMandatoryFieldId (state, id) {
    Vue.set(state, 'localMandatoryFieldId', id)
  },
  setLocalMetricId (state, id) {
    Vue.set(state, 'localMetricId', id)
  },
  gotRefGranularityTypes (state, granularity) {
    Vue.set(state, 'granularityTypes', granularity)
  },
  gotRefEvents (state, events) {
    Vue.set(state, 'refEvents', events)
  },
  setLoadingRefEvents (state, loading) {
    Vue.set(state, 'loadingRefEvents', loading)
  },
  gotRefEventTypes (state, types) {
    Vue.set(state, 'refEventTypes', types)
  },
  setLoadingRefEventTypes (state, loading) {
    Vue.set(state, 'loadingRefEventTypes', loading)
  },
  incrementLocalRefEventId (state) {
    Vue.set(state, 'localRefEventId', state.localRefEventId + 1)
  }
}

const getters = {
  getFeeds (state) {
    return state.feeds
  },
  getLoadingFeeds (state) {
    return state.loadingFeeds
  },
  getSavedFeed (state) {
    return state.savedFeed
  },
  getLoadingFeed (state) {
    return state.loadingFeed
  },
  getCurrentFeed (state) {
    return state.currentFeed
  },
  getSources (state) {
    return state.sources
  },
  getLoadingSources (state) {
    return state.loadingSources
  },
  getSavedSource (state) {
    return state.savedSource
  },
  getLoadingSource (state) {
    return state.loadingSource
  },
  getCurrentSource (state) {
    return state.currentSource
  },
  getLocationContent (state) {
    return state.locationContent
  },
  getLoadingLocationContent (state) {
    return state.loadingLocationContent
  },
  getFileContent (state) {
    return state.fileContent
  },
  getLoadingFileContent (state) {
    return state.loadingFileContent
  },
  getLoadingSetupTransformations (state) {
    return state.loadingSetupTransformations
  },
  getMandatoryFieldsNames (state) {
    return state.mandatoryFieldsNames
  },
  getNiceToHaveFieldsNames (state) {
    return state.niceToHaveFieldsNames
  },
  getVariablesNames (state) {
    return state.variablesNames
  },
  getMetricsNames (state) {
    return state.metricsNames
  },
  getMandatoryFields (state) {
    return state.mandatoryFields
  },
  getVariables (state) {
    return state.variables
  },
  getMetrics (state) {
    return state.metrics
  },
  getGranularityTypes (state) {
    return state.granularityTypes
  },
  getRefEvents (state) {
    return state.refEvents
  },
  getLoadingRefEvents (state) {
    return state.loadingRefEvents
  },
  getRefEventTypes (state) {
    return state.refEventTypes
  },
  getLoadingRefEventTypes (state) {
    return state.loadingRefEventTypes
  },
  getLocalRefEventId (state) {
    return state.localRefEventId
  },
  getSimulationId (state) {
    return state.simulationId
  },
  getLocalVariableId (state) {
    return state.localVariableId
  },
  getLocalMandatoryFieldId (state) {
    return state.localMandatoryFieldId
  },
  getLocalMetricId (state) {
    return state.localMetricId
  }
}

export const thirdPartyFeed = {
  actions,
  mutations,
  state,
  getters
}
